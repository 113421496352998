import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

const oidcConfig: AuthProviderProps = {
    authority: window.__RUNTIME_CONFIG__.REACT_APP_OIDC_AUTHORITY ?? '',
    client_id: window.__RUNTIME_CONFIG__.REACT_APP_OIDC_CLIENT_ID ?? '',
    redirect_uri: window.__RUNTIME_CONFIG__.REACT_APP_OIDC_REDIRECT_URI ?? '',
    client_secret: window.__RUNTIME_CONFIG__.REACT_APP_OIDC_CLIENT_SECRET,
    scope: 'openid email',
    userStore: new WebStorageStateStore({ store: window.localStorage, prefix: 'jOIDC' }),
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig}>
            <App />
        </AuthProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
