export const LANG = {
    fr: {
        MAIN_TITLE: 'Bienvenue dans le AirSpot',
        TITLE_COMPLEMENT: 'Faire réussir le collectif',
        PLATFORMS_DESCRIPTION: "Où allez vous travailler aujourd'hui ?",
        JLAND_LOGO_ALT: 'Logo Jamespot Land',
        JAMESPOT_LOGO_ALT: 'Logo Jamespot',
        CONNECT_ERROR: 'Il y a eu un problème lors du processus de connexion, voulez-vous réessayer ?',
        MY_PLATFORMS: 'Mes plateformes:',
        RETRY: 'Réessayer',
        GO_TO_HOME: "Retour à l'accueil",
        MADE_WITH_LOVE_BY: 'Made with ❤️ by ',
        CONNECT_TEXT_1: "Utilisateur d'une plateforme Jamespot ?",
        CONNECT_TEXT_2: 'Vous pouvez vous connecter pour accéder aux espaces privés de vos plateformes.',
        CONNECT_TEXT_3: "(Une popup devrait s'ouvrir pour la connexion)",
        BUTTON_LOGIN: 'Se connecter',
        MY_PLATFORM: 'Ma plateforme',
        MY_OFFICE: 'Mes bureaux virtuels',
        AIRSPOT_TITLE: 'Bienvenue dans le AirSpot',
        PUBLIC_MAP_VILLAGE_TITLE: 'Le village ',
        PUBLIC_MAP_VILLAGE_DESCRIPTION: 'Profitez du grand air et rencontrez du monde',
        PUBLIC_MAP_COWORKING_TITLE: 'Co-Working',
        PUBLIC_MAP_COWORKING_DESCRIPTION: 'Et retrouvez-y vos partenaires de travail ?',
        PUBLIC_MAP_COFFEE_TITLE: 'Le café',
        PUBLIC_MAP_COFFEE_DESCRIPTION: 'Pour favoriser la concentration ou l’échange',
        PUBLIC_MAP_SEA_TITLE: 'La mer',
        PUBLIC_MAP_SEA_DESCRIPTION: 'On prend une pause ?',
        PUBLIC_MAP_AMPHITHEATER_TITLE: 'L’amphithéâtre',
        PUBLIC_MAP_AMPHITHEATER_DESCRIPTION: 'Animez une conférence ou assistez y',
        PUBLIC_MAP_CLIENT_TITLE: 'Mes bureaux',
        PUBLIC_MAP_QG_TITLE: 'Quartier général',
        PUBLIC_MAP_QG_DESCRIPTION: 'Venez découvrir Jamespot et ses collaborateurs',
        LOGOUT: 'Se déconnecter',
    },
    en: {
        MAIN_TITLE: 'Bienvenue dans le AirSpot',
        TITLE_COMPLEMENT: 'Make your team succeed',
        PLATFORMS_DESCRIPTION: 'Where would you like to work today ?',
        CONNECT_ERROR: 'There was an error during the connection process, would you like to retry?',
        JLAND_LOGO_ALT: 'Logo Jamespot Land',
        JAMESPOT_LOGO_ALT: 'Logo Jamespot',
        MY_PLATFORMS: 'My platforms:',
        RETRY: 'Retry',
        GO_TO_HOME: 'Go back to homepage',
        MADE_WITH_LOVE_BY: 'Made with ❤️ by ',
        CONNECT_TEXT_1: "Jamespot user ?",
        CONNECT_TEXT_2: 'You can login to access your platform\'s private spaces.',
        CONNECT_TEXT_3: "(A popup should open for login)",
        BUTTON_LOGIN: 'Login',
        MY_PLATFORM: 'My platform',
        MY_OFFICE: 'My virtual offices',
        PUBLIC_MAP_VILLAGE_TITLE: 'The village ',
        PUBLIC_MAP_VILLAGE_DESCRIPTION: 'Enjoy the fresh air and meet people',
        PUBLIC_MAP_COWORKING_TITLE: 'Co-Working',
        PUBLIC_MAP_COWORKING_DESCRIPTION: 'And meet your work partners there?',
        PUBLIC_MAP_COFFEE_TITLE: 'The coffee shop',
        PUBLIC_MAP_COFFEE_DESCRIPTION: 'To promote concentration or exchange',
        PUBLIC_MAP_SEA_TITLE: 'The sea',
        PUBLIC_MAP_SEA_DESCRIPTION: 'Shall we take a break?',
        PUBLIC_MAP_AMPHITHEATER_TITLE: 'The amphitheatre',
        PUBLIC_MAP_AMPHITHEATER_DESCRIPTION: 'Host or attend a conference',
        PUBLIC_MAP_CLIENT_TITLE: 'My officies',
        PUBLIC_MAP_QG_TITLE: 'Jamespot HQ',
        LOGOUT: 'Logout',
    },
};

export function getNavigatorLanguage() {
    return navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language || 'fr';
}

export function getLang(locale: string) {
    return locale in LANG ? LANG[locale as 'fr' | 'en'] : LANG.fr;
}
