import React from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {Platform} from '../utils/types';
import logo from '../resources/jamespot-logo-white.svg';
import illustration from '../resources/illustration.png';
import linkTo from '../resources/link-to.svg';
import cloud from '../resources/cloud.svg';
import styled from 'styled-components';
import {getMyPlatforms} from '../utils/api';
import {Login} from '../components/Login';
import {Skeleton} from '../components/Skeleton';
import {Logout} from '../components/Logout';
import {useAuth} from "react-oidc-context";

const Main = styled.main`
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    --col-size: 360px;
`;

const Text = styled.p`
    margin: 0;
    font-weight: 800;
    font-size: 20px;
    line-height: 1.2;
    color: white;
    text-align: center;
`;

const StyledLink = styled.a`
    margin-top: 32px;
    color: white;
    text-align: center;
    z-index: 2;
    padding: 8px 16px;
`;

const ColumnLeftFixed = styled.div`
    display: none;

    @media (min-width: 930px) {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
    }
`;

const ColumnLeftRelative = styled.div`
    @media (min-width: 930px) {
        position: relative;
        width: var(--col-size);
        background: linear-gradient(var(--accent-sea-blue), var(--accent-sea-green));
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px 25px 20px 25px;
        height: 100vh;

        &:after {
            content: '';
            position: absolute;
            background: url(${illustration});
            background-size: cover;
            left: -35vh;
            width: calc(100vh * 512 / 654);
            height: 100vh;
            top: 50%;
            transform: translateY(-40%) rotate(-18deg) scale(0.65);
        }
    }
`;

const TopImg = styled.img`
    margin-bottom: 32px;
    width: 90%;
`;

const Spacing = styled.div`
    flex-grow: 1;
`;

const MadeWithLove = styled.span`
    display: flex;
    align-items: center;
    color: white;
    font-size: 14px;
`;

const MadeWithLoveLink = styled.a`
    padding: 14px 8px 7px;
`;

const MadeWithLoveImg = styled.img`
    width: 120px;
`;

const ColumnRight = styled.div`
    padding: 18px;
    width: 100vw;
    position: relative;

    @media (min-width: 570px) {
        padding-left: 80px;
        padding-right: 80px;
    }

    @media (min-width: 930px) {
        width: 100vw;
        padding-left: calc(var(--col-size) + 80px);
        max-width: 1600px;
    }

    @media (min-width: 1420px) {
        &:after {
            content: '';
            position: absolute;
            background: url(${cloud});
            background-size: cover;
            width: 656px;
            height: 153px;
            top: 40px;
            right: 0;
            transform: scale(0.75);
        }
    }
`;

const Title = styled.h1`
    font-size: 42px;
    line-height: 1.2;
    text-align: left;

    @media (min-width: 930px) {
        font-size: 52px;
        max-width: 450px;
    }
`;

const Highlight = styled.span`
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: 16px;
        left: -4px;
        right: -4px;
        height: 20px;
        border-radius: 2px;
        background-color: var(--accent-sea-blue);
        z-index: -1;
    }
`;

const GoHome = styled.h2`
    font-size: 18px;
    font-weight: 700;
    margin: 16px 0;
`;

const Destinations = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 16px 0 32px 0;

    @media (min-width: 570px) {
        max-width: 500px;
    }
`;

const Destination = styled.div<{ platformColor: string }>`
    position: relative;
    border-radius: 12px;
    background-color: var(--accent-grey-1);
    padding: 12px 72px 16px 36px;
    transition: all 0.25s;
    font-size: 14px;
    font-weight: 500;
    --map-color: turquoise;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 16px;
        border-radius: 12px 0 0 12px;
        background-color: ${({platformColor}) => platformColor};
    }
`;

const DestinationNav = `
    text-decoration: none;
    position: relative;
    padding-left: 30px;
    
    &:before {
        content: '';
        position: absolute;
        background: url(${linkTo});
        background-size: cover;
        left: 0;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.25s;
    }
    
    &:hover:before {
        left: 4px;
    }
`;

const DestinationAnchor = styled.a`
    ${DestinationNav}
`;

const DestinationLink = styled(Link)`
    ${DestinationNav}
`;

const DestinationTitle = styled.p`
    font-size: 20px;
    font-weight: 700;
    margin: 0;
`;

export function Hub() {
    const intl = useIntl();
    const auth = useAuth();

    const [loading, setLoading] = React.useState(true);
    const [platforms, setPlatforms] = React.useState<Platform[]>([]);

    React.useEffect(() => {
        if (auth.user && auth.isAuthenticated) {
            getMyPlatforms(auth.user.access_token)
                .then((platforms) => {
                    setPlatforms(platforms);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        }
    }, [auth]);

    const title = intl.formatMessage({id: 'MAIN_TITLE'});
    const firstWordsTitle = title.split(' ').slice(0, -1).join(' ');
    const lastWordTitle = title.split(' ').splice(-1);

    const isUserLogged = auth.user && auth.isAuthenticated;

    return (
        <>
            <Main>
                <ColumnLeftFixed>
                    <ColumnLeftRelative>
                        <TopImg src={logo} alt={intl.formatMessage({id: 'JLAND_LOGO_ALT'})}/>
                        <Text>
                            <FormattedMessage id="TITLE_COMPLEMENT"/>
                        </Text>
                        <StyledLink href={window.__RUNTIME_CONFIG__.REACT_APP_URL_JAMESPOT}>
                            <FormattedMessage id="GO_TO_HOME"/>
                        </StyledLink>
                        <Spacing/>
                        {isUserLogged && <Logout/>}
                        <MadeWithLove>
                            <FormattedMessage id="MADE_WITH_LOVE_BY"/>
                            <MadeWithLoveLink
                                href={window.__RUNTIME_CONFIG__.REACT_APP_URL_JAMESPOT}
                                target="_blank"
                                rel="noreferrer">
                                <MadeWithLoveImg src={logo} alt={intl.formatMessage({id: 'JAMESPOT_LOGO_ALT'})}/>
                            </MadeWithLoveLink>
                        </MadeWithLove>
                    </ColumnLeftRelative>
                </ColumnLeftFixed>
                <ColumnRight>
                    <Title>
                        {firstWordsTitle} <Highlight>{lastWordTitle}</Highlight>
                    </Title>
                    <GoHome>
                        <FormattedMessage id="PLATFORMS_DESCRIPTION"/>
                    </GoHome>
                    <Destinations>
                        {!isUserLogged && <Login/>}
                        {loading && isUserLogged && Array.from(Array(3).keys()).map((i) => <Skeleton key={i}/>)}
                        {isUserLogged &&
                            platforms.map((platform) => (
                                <Destination key={platform.dbName} platformColor={platform.color}>
                                    <DestinationTitle>{platform.name}</DestinationTitle>
                                    <DestinationAnchor
                                        href={
                                            'https://' + platform.domain + '/?action=goOAuth&serviceName=myjamespot'
                                        }>
                                        <FormattedMessage id={`MY_PLATFORM`}/>
                                    </DestinationAnchor>
                                    <br/>
                                    {platform.jLand.active === 1 && (
                                        <DestinationLink
                                            to={window.__RUNTIME_CONFIG__.REACT_APP_URL_JLAND + '/office/' + platform.domain}>
                                            <FormattedMessage id={`MY_OFFICE`}/>
                                        </DestinationLink>
                                    )}
                                    {platform.jLand.maps.map((map) => (
                                        <React.Fragment key={map.uri}>
                                            <br/>
                                            <DestinationLink
                                                to={window.__RUNTIME_CONFIG__.REACT_APP_URL_JLAND + '/map/' + platform.domain + '/' + map.uri}>
                                                {map.title}
                                            </DestinationLink>
                                        </React.Fragment>
                                    ))}
                                </Destination>
                            ))}
                    </Destinations>
                </ColumnRight>
            </Main>
        </>
    );
}
