import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ScrollToTop } from './components/ScrollToTop';
import { BaseStyle } from './components/BaseStyle';
import { Hub } from './routes/Hub';
import { getLang, getNavigatorLanguage } from './utils/lang';

function App() {
    const [locale] = React.useState(getNavigatorLanguage());

    return (
        <IntlProvider
            messages={getLang(locale)}
            locale={locale}
            defaultLocale="fr"
            onError={() => {
                /**/
            }}>
            <BrowserRouter>
                <ScrollToTop>
                    <Routes>
                        <Route path="/" element={<BaseStyle />}>
                            <Route path="/" element={<Hub />}>
                                <Route path={'/hub'} element={<Hub />} />
                            </Route>
                        </Route>
                        {/**************************************************
                         *
                         PLEASE REGISTER PUBLIC ROUTES IN public/sitemap.xml
                         *
                         ***************************************************/}
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        </IntlProvider>
    );
}

export default App;
