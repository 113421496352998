import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useAuth } from 'react-oidc-context';

const Container = styled.div`
    font-size: 1.5rem;
    color: var(--text-color);
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const Text = styled.p`
    display: block;
    font-size: 1rem;
`;

const Button = styled.button`
    font-weight: bold;
    color: var(--text-color);
    border: solid 1px var(--text-color);
    border-radius: 2rem;
    text-decoration: none;
    background-color: transparent;
    padding: 0.5em 1.2em 0.4em;
    transition: all 0.25s;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;

    &:hover,
    &:focus {
        background-color: var(--accent-dark-blue);
        color: var(--accent-dark-blue-text-color);
    }
`;

export function Login() {
    const auth = useAuth();

    return (
        <Container>
            <Text>
                <FormattedMessage id="CONNECT_TEXT_1" /> <br />
                <FormattedMessage id="CONNECT_TEXT_2" /> <br />
                <br />
                <Button onClick={() => auth.signinPopup({ popupWindowFeatures: { width: 400, height: 350 } })}>
                    <FormattedMessage id="BUTTON_LOGIN" />
                </Button>
            </Text>
        </Container>
    );
}
