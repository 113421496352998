import React from 'react';
import styled from 'styled-components';
import { useAuth } from 'react-oidc-context';
import { FormattedMessage } from 'react-intl';

const Text = styled.p`
    color: white;
    text-align: center;
    z-index: 2;
    text-decoration: underline;
    margin: 0;
    font-size: 14px;

    &:hover {
        cursor: pointer;
    }
`;

export function Logout() {
    const auth = useAuth();

    return (
        <Text
            onClick={() => {
                auth.signoutPopup({ popupWindowFeatures: { width: 400, height: 400 } });
            }}>
            <FormattedMessage id={`LOGOUT`} />
        </Text>
    );
}
