import React from 'react';
import { Outlet } from 'react-router-dom';
import '../style/base.css';

export function BaseStyle() {
    return (
        <>
            <Outlet />
        </>
    );
}
