import { Platform } from './types';

const BASE_URL = window.__RUNTIME_CONFIG__.REACT_APP_URL_MYJAMESPOT;
export const PLATFORMS_ADDRESS = BASE_URL + '/jconnect/platforms';

const authorizationHeader = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } });

export async function getMyPlatforms(token: string): Promise<Platform[]> {
    return fetch(PLATFORMS_ADDRESS, authorizationHeader(token)).then((response) =>
        response.json(),
    );
}
